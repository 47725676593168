/* eslint-disable no-undef */
import React from 'react'

import { messaging } from './firebase'

import { FancyToastStyle } from 'utils/css/toastStyles'
import mixpanel from 'utils/js/mixpanel'
import CrispChat from 'utils/js/crisp-chat'

import useFancyToast from 'components/02-molecules/useFancyToast'
import FancyToast from 'components/03-components/FancyToast'

interface Context {
  isAuthenticated?: boolean
  login?: () => void
  logout?: () => void
}

const basepath = `${process.env.REACT_APP_BASE_DOMAIN}api/client/`
const VAP_ID = process.env.REACT_APP_FIREBASE_VAPID_KEY

export const AuthContext = React.createContext<Context>({})

export const AuthProvider: React.FC<{}> = ({ children }) => {
  const [isAuthenticated, setIsAuth] = React.useState(
    Boolean(localStorage.getItem('token_web')),
  )
  const { toast, setToast, showToast } = useFancyToast()

  React.useEffect(() => {
    mixpanel.crispChatMessage()
    window.scrollTo(0, 0)
  }, [])

  const sendFCMTokenToBackend = async (token: string) => {
    const getTokenWeb = () => {
      let tokenWeb = localStorage.getItem('token_web')
      return tokenWeb ? `JWT ${tokenWeb}` : ''
    }

    const getHeaders = (): HeadersInit => {
      const token = getTokenWeb()
      const headers: Record<string, string> = {
        'Accept-Language': 'es',
        'Content-Type': 'application/json',
      }
      if (token) {
        headers.Authorization = token
      }
      return headers
    }
    const apiUrl = `${basepath}webfcmtoken/`
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ token }),
    }

    try {
      const response = await fetch(apiUrl, requestOptions)
      if (!response.ok) {
        throw new Error('Token update was not performed')
      }
      showToast(
        FancyToastStyle.SUCCESS,
        'Success',
        'Successfully updated token',
      )
      console.log('Successfully updated token')
    } catch (error) {
      showToast(
        FancyToastStyle.ERROR,
        'Error',
        'Token update was not performed',
      )
    }
  }

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      try {
        const token = await messaging.getToken({ vapidKey: VAP_ID })
        // console.log('requestNotificationPermissionToken: ', token)
        if (token) {
          const storedToken = localStorage.getItem('fcm_token')
          if (storedToken !== token) {
            localStorage.setItem('fcm_token', token)
            await sendFCMTokenToBackend(token)
          }
        }
      } catch (error) {
        showToast(
          FancyToastStyle.ERROR,
          'Error',
          'Token update was not performed',
        )
      }
    } else if (permission === 'denied') {
      showToast(
        FancyToastStyle.WARNING,
        'Permission',
        'Por favor, habilita las notificaciones en tu navegador.',
      )
    } else {
      showToast(
        FancyToastStyle.INFO,
        'Permission',
        'Por favor, permite las notificaciones para recibir actualizaciones.',
      )
    }
  }

  const login = React.useCallback(async () => {
    setIsAuth(true)
    await requestNotificationPermission() // Solicitar permisos de notificación al iniciar sesión
  }, [])

  const logout = React.useCallback(() => {
    mixpanel.logout()
    localStorage.clear()
    setIsAuth(false)
    CrispChat.logout()
  }, [])

  React.useEffect(() => {
    if (isAuthenticated) {
      messaging.onMessage(payload => {
        console.log('Message received. ', payload)

        const notificationTitle =
          payload.notification && payload.notification.title
            ? payload.notification.title
            : 'Notificación'
        const notificationBody =
          payload.notification && payload.notification.body
            ? payload.notification.body
            : 'Tienes una nueva notificación.'

        showToast(FancyToastStyle.INFO, notificationTitle, notificationBody)

        if (Notification.permission === 'granted') {
          navigator.serviceWorker.getRegistration().then(registration => {
            if (registration) {
              registration.showNotification(notificationTitle, {
                body: notificationBody,
                icon: '/firebase-logo.png',
              })
            }
          })
        }
      })
    }
  }, [isAuthenticated, showToast])

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
      {toast && (
        <FancyToast
          type={toast.type}
          title={toast.title}
          message={toast.message}
          onClose={() => setToast(null)}
        />
      )}
    </AuthContext.Provider>
  )
}
